import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import ScrappingScneario from './components/ScrappingScenario' 
import LoginPage from './components/LoginPage';

const App: React.FC = () => {
  
  const [login, setLogin] = useState<boolean>(false);

  const handleLogin = (verified: boolean) => {
    setLogin(verified);
  }
  
  return (
    <Router>
      {login ? (
        <Routes>
          <Route path="/" element={<ScrappingScneario />}/>
        </Routes>
      ) : (
        <LoginPage onVerify={handleLogin} />
      )}
    </Router>
  );
};

export default App;
