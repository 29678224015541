import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import styled from '@emotion/styled';
import LoadingSpinner from '../LoadingSpinner';

interface chapitre {
  chapitre_name: string;
  start_time?: number;
  end_time?: number;
}

interface VideoLink {
  ID: string;
  Lien: string;
  State: string;
}

const ChapterButtonGrid = styled.div`
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  gap: 20px;
  justify-content: center;
  justify-items: center;
`;

const Table = styled.table`
    width: 80%;
    margin: 20px auto;
    border-collapse: collapse;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  `;

  const Th = styled.th`
    padding: 12px;
    background-color: #007bff;
    color: white;
    border: 1px solid #ddd;
  `;

  const Td = styled.td`
    padding: 12px;
    border: 1px solid #ddd;
    text-align: center;
  `;

  const Tr = styled.tr`
    &:nth-of-type(even) {
      background-color: #f9f9f9;
    }
    &:hover {
      background-color: #f1f1f1;
    }
  `;

  const RemoveButton = styled.button`
    background-color: #ff4d4f;
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    font-size: 16px;
    line-height: 0;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: #ff7875;
    }
`;

const ScrappingScenario: React.FC = () => {

  const plageStart = localStorage.getItem('plageStart');
  const plageEnd = localStorage.getItem('plageEnd');
  const userLocalName = localStorage.getItem('username');
  
  const [videosLinks, setVideosLinks] = useState<VideoLink[]>([]);
  const [index, setIndex] = useState<number>(0);
  const [actualVideo, setActualVideo] = useState<string>();
  const [isActualVideo, setIsActualVideo] = useState<boolean>(false);

  const [inputValue, setInputValue] = useState('');

  const [allChapitre, setAllChapitre] = useState<chapitre[]>([]);

  const intro_Icon = `${process.env.PUBLIC_URL}/Symbols/Introduction.jpg`; 
  const other_Icon = `${process.env.PUBLIC_URL}/Symbols/Other.jpg`; 
  const caresse_Icon = `${process.env.PUBLIC_URL}/Symbols/Caresse.jpg`; 

  const sixNine_Icon = `${process.env.PUBLIC_URL}/Symbols/Six_Neuf.png`; 
  const andromaque_Icon = `${process.env.PUBLIC_URL}/Symbols/Gemini.png`;
  const bateauIvre_Icon = `${process.env.PUBLIC_URL}/Symbols/Bateau_Ivre.png`;
  const branletteEspagnole_Icon = `${process.env.PUBLIC_URL}/Symbols/Branlette_Espagnole.png`;
  const cowgirl_Icon = `${process.env.PUBLIC_URL}/Symbols/Cowgirl.png`;
  const chaiseBascule_Icon = `${process.env.PUBLIC_URL}/Symbols/Reverse_cowgirl.png`;
  const anulingus_Icon = `${process.env.PUBLIC_URL}/Symbols/Anulingus.png`;
  const ejaculation_Icon = `${process.env.PUBLIC_URL}/Symbols/Ejaculation.png`;
  const cunnilingus_Icon = `${process.env.PUBLIC_URL}/Symbols/Cunni.png`;
  const hommeDebout_Icon = `${process.env.PUBLIC_URL}/Symbols/Homme_debout.png`;
  const levrette_Icon = `${process.env.PUBLIC_URL}/Symbols/Levrette.png`;
  const levretteDebout_Icon = `${process.env.PUBLIC_URL}/Symbols/Levrette_debout.png`;
  const masturbation_Icon = `${process.env.PUBLIC_URL}/Symbols/Masturber.png`;
  const leClou_Icon = `${process.env.PUBLIC_URL}/Symbols/Clou.png`;
  const petiteCuilleres_Icon = `${process.env.PUBLIC_URL}/Symbols/Cuillere.png`;
  const pipe_Icon = `${process.env.PUBLIC_URL}/Symbols/Pipe.png`;
  const unionElephant_Icon = `${process.env.PUBLIC_URL}/Symbols/Union_Elephant.png`;
  const missionary_Icon = `${process.env.PUBLIC_URL}/Symbols/Missionaire_allongee.png`;
  const missionaryDebout_Icon = `${process.env.PUBLIC_URL}/Symbols/Missionaire_debout.png`;
  const eagle_Icon = `${process.env.PUBLIC_URL}/Symbols/Eagle.png`;
  const grasshopper_Icon = `${process.env.PUBLIC_URL}/Symbols/Grasshopper.png`;
  const HommePorte_Icon = `${process.env.PUBLIC_URL}/Symbols/Homme_porte.png`;
  const slowDance_Icon = `${process.env.PUBLIC_URL}/Symbols/Slow_dance.png`;
  const vis_Icon = `${process.env.PUBLIC_URL}/Symbols/Vis.png`;
  const placeConfort_Icon = `${process.env.PUBLIC_URL}/Symbols/place_confortable.png`;

  const fetchVideos = async () => {
    console.log("Start :", plageStart);
    console.log("End :", plageEnd);
    try {
      if(plageStart && plageEnd) {
        const response = await axios.get(`https://xclusiveserveurintern.online/api/ANTIMUS/get_scrapping_videos`, {
          params: {
            plageStart,
            plageEnd,
          },
        });
  
        setVideosLinks(response.data); 
        console.log(response.data);
      }
    } catch (err) {
      console.error("Erreur lors de la récupération des vidéos :", err);
    }
  };

  useEffect(() => {
    fetchVideos();
  }, [plageStart, plageEnd]);

  useEffect(() => {
    if(videosLinks.length > 0 && videosLinks[index]) {
      setActualVideo(`https://xclusiveserveurintern.online/videos/video_${videosLinks[index].ID}.mp4`);
      setIsActualVideo(true);
    } else {
      setActualVideo('');
    }
  },[videosLinks, index]);

  const handleImgClick = (name: string) => {
    setAllChapitre((prevChapitres) => {
      // Rechercher le premier chapitre sans `end_time`
      const indexSansEndTime = prevChapitres.findIndex(chap => chap.end_time === undefined);

      // Si un chapitre sans `end_time` est trouvé, le remplacer par le nouveau `chapitre_name`
      if (indexSansEndTime !== -1) {
        const newChapitres = [...prevChapitres];
        newChapitres[indexSansEndTime] = {
          ...newChapitres[indexSansEndTime],
          chapitre_name: name,  // Remplacer le nom du chapitre
        };
        return newChapitres;
      }

      // Si tous les chapitres ont un `end_time` défini, ajouter un nouveau chapitre
      const newChapitre: chapitre = {
        chapitre_name: name,
      };
      return [...prevChapitres, newChapitre];
    });
  };

const handleStartButton = () => {
  // Vérifie si le dernier élément de allChapitre existe
  setAllChapitre((prevChapitres) => {
  // Si la liste est vide, on ne fait rien
  if (prevChapitres.length === 0) return prevChapitres;

  // Clone de la liste des chapitres pour éviter de modifier directement l'état précédent
  const updatedChapitres = [...prevChapitres];

  // Récupérer le dernier chapitre
  const lastChapitreIndex = updatedChapitres.length - 1;

  // Définir le `start_time` du dernier chapitre en utilisant `videoRef.current?.currentTime`
  updatedChapitres[lastChapitreIndex] = {
    ...updatedChapitres[lastChapitreIndex],
    start_time: videoRef.current ? Math.floor(videoRef.current.currentTime) : 0,
  };

  return updatedChapitres;
  });
};

const handleEndButton = () => {
  // Vérifie si le dernier élément de allChapitre existe
  setAllChapitre((prevChapitres) => {
  // Si la liste est vide, on ne fait rien
  if (prevChapitres.length === 0) return prevChapitres;

  // Clone de la liste des chapitres pour éviter de modifier directement l'état précédent
  const updatedChapitres = [...prevChapitres];

  // Récupérer le dernier chapitre
  const lastChapitreIndex = updatedChapitres.length - 1;

  // Définir le `start_time` du dernier chapitre en utilisant `videoRef.current?.currentTime`
  updatedChapitres[lastChapitreIndex] = {
    ...updatedChapitres[lastChapitreIndex],
    end_time: videoRef.current ? Math.floor(videoRef.current.currentTime) : 0,
  };

  return updatedChapitres;
  });
};

// Fonction pour gérer la sauvegarde des chapitres
const saveChapitres = async (idVideo: string) => {
  if (idVideo === '' || allChapitre.length === 0) {
    alert('You can\'t save 0 chapter for the video.');
    return;
  }

  try {
    // Appel à l'API pour sauvegarder les chapitres
    const response = await axios.post('https://xclusiveserveurintern.online/api/ANTIMUS/save_chapitres', {
      id_video: idVideo,
      username: userLocalName,
      chapitres: allChapitre,
    });

    console.log('Réponse du serveur :', response.data);
    alert('Chapters successfully saved !');
  } catch (error) {
    console.error('Error saving chapters :', error);
    alert('Error saving chapters.');
  }
};

const handleDoneButton = () => {
  if(allChapitre.length > 0) {
      saveChapitres(videosLinks[index].ID);

      setAllChapitre([]);

      const newIndex = index+1;
      setIndex(newIndex);
    }
};

// Fonction pour gérer la sauvegarde des chapitres
const flagVideo = async (idVideo: string, commentaire: string) => {
  let APIcomment;
  if(commentaire === '') {
    APIcomment = "Regular Flag Comment"
  } else {
    APIcomment = commentaire;
  }

  if (idVideo === '') {
    alert('Problem to flag the video. Please contact the admin.');
    return;
  }

  try {
    // Appel à l'API pour sauvegarder les chapitres
    const response = await axios.post('https://xclusiveserveurintern.online/api/ANTIMUS/flag_video', {
      id_video: idVideo,
      comment: APIcomment
    });

    alert('Video flag successfully !');
  } catch (error) {
    console.error('Flag error :', error);
    alert('Flag error. Please contact the admin.');
  }
};

const [flagComment, setFlagComment] = useState<string>("");

const handleFlagButton = () => {
    flagVideo(videosLinks[index].ID, flagComment);
    setAllChapitre([]);

    const newIndex = index+1;
    setIndex(newIndex);
};

// Utiliser useEffect pour recharger la vidéo lorsqu'actualVideo change
useEffect(() => {
  if (videoRef.current && actualVideo) {
    // Recharger la vidéo avec la nouvelle source
    videoRef.current.pause();
    videoRef.current.load();
    videoRef.current.play();
  }
}, [actualVideo]);

const handleRemoveChapter = (index: number) => {
  setAllChapitre((prevChapitres) => prevChapitres.filter((_, i) => i !== index));
};

const [videoTime, setVideoTime] = useState<number>(0);
const videoRef = useRef<HTMLVideoElement | null>(null);

const handleEditTime = (type: string, nb:number) => {
  if(videoRef.current) {
    if(type === "plus") {
      videoRef.current.currentTime += nb;
    } else if (type === "moins") {
      videoRef.current.currentTime -= nb;
    }
  }
};

useEffect(() => {
  const videoElement = videoRef.current;

    // Fonction pour mettre à jour le temps de la vidéo
    const updateTime = () => {
      if(videoElement) {
        setVideoTime(Math.floor(videoElement.currentTime));
      }
    };

    // Ajouter l'écouteur d'événement `timeupdate`
    if (videoElement) {
      videoElement.addEventListener('timeupdate', updateTime);
    }
  
  // Nettoyage de l'écouteur d'événement lorsque le composant est démonté
  return () => {
    if (videoElement) {
      videoElement.removeEventListener('timeupdate', updateTime);
    }
  };
}, [actualVideo]);

  // Fonction intermédiaire pour vérifier si le champ est vide
  const handleButtonClick = () => {
    if (inputValue.trim() === '') {
      alert('Please fill in the other character field');
    } else {
      handleImgClick(inputValue); // Appelle handleImgClick avec la valeur saisie
      setInputValue('');
    }
  };

  return (
    <div style={{ padding: '20px', textAlign: 'center', backgroundColor: '#D3D3D3', minHeight: '100vh'}}>
      {!videosLinks[index] ? (
        <p>No more video to timestamp</p>
      ) : (
        <>
        {isActualVideo ? (
          <>
          <div style={{ marginTop: '20px' }}>
          <button
            onClick={handleFlagButton}
            style={{
              padding: '10px 20px',
              fontSize: '16px',
              borderRadius: '5px',
              backgroundColor: '#ff3d3d',
              color: 'white',
              border: 'none',
              cursor: videosLinks[index] ? 'pointer' : 'not-allowed', // Changer le curseur si désactivé
              opacity: videosLinks[index] ? 1 : 0.6  // Rendre le bouton plus transparent s'il est désactivé
            }}
            disabled={!videosLinks[index]}
          >
            Flag the video
          </button>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
          <label htmlFor="flag-comment" style={{ marginRight: '5px' }}>Flag Comment:</label>
            <textarea
              id="flag-comment"
              value={flagComment}  // État pour stocker la valeur du commentaire
              onChange={(e) => setFlagComment(e.target.value)}  // Mettre à jour l'état lors de la saisie
              placeholder="Enter your comment here"
              style={{
                width: '500px',
                height: '50px',
                padding: '10px',
                fontSize: '14px',
                borderRadius: '5px',
                border: '1px solid #ccc',
              }}
            />
          </div>

            <h2>Vidéo ID n°{videosLinks[index].ID} :</h2>
            <video ref={videoRef} controls width="600">
              <source src={actualVideo} type="video/mp4" />
              Votre navigateur ne supporte pas la lecture vidéo.
            </video>
          </div>

          <p>Current video time : {videoTime}</p>
          
          <button
            onClick={() => handleEditTime("moins", 30)}>-30 sec
          </button>
          <button
            onClick={() => handleEditTime("moins", 5)}>-5 sec
          </button>
          <button
            onClick={() => handleEditTime("moins", 1)}>-1 sec
          </button>
          <button
            onClick={() => handleEditTime("plus", 1)}>+1 sec
          </button>
          <button
            onClick={() => handleEditTime("plus", 5)}>+5 sec
          </button>
          <button
            onClick={() => handleEditTime("plus", 30)}>+30 sec
          </button>
         
        <p></p>
  
        <button
          onClick={handleStartButton}
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            borderRadius: '5px',
            backgroundColor: '#007bff',
            color: 'white',
            border: 'none',
            cursor: 'pointer',
            marginRight: "10px",
          }}
        >
          Start Time
        </button>
          
        <button
          onClick={handleEndButton}
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            borderRadius: '5px',
            backgroundColor: '#007bff',
            color: 'white',
            border: 'none',
            cursor: 'pointer'
          }}
        >
          End Time
        </button>
        </>
        ) : (
          <LoadingSpinner />
        )}
        </>
      )}

      <ChapterButtonGrid>
        <img src={intro_Icon} style={{cursor: 'pointer', height: '100px'}} onClick={() => handleImgClick("Introduction")}/>
        <img src={caresse_Icon} style={{cursor: 'pointer', height: '100px'}} onClick={() => handleImgClick("Caresses")}/>
        
        <img src={masturbation_Icon} style={{cursor: 'pointer', height: '100px'}} onClick={() => handleImgClick("Masturbation")} />
        <img src={pipe_Icon} style={{cursor: 'pointer', height: '100px'}} onClick={() => handleImgClick("Pipe")} />
        <img src={branletteEspagnole_Icon} style={{cursor: 'pointer', height: '100px'}} onClick={() => handleImgClick("Branlette espagnole")} />
        <img src={cunnilingus_Icon} style={{cursor: 'pointer', height: '100px'}} onClick={() => handleImgClick("Cunnilingus")} />  
        <img src={anulingus_Icon} style={{cursor: 'pointer', height: '100px'}} onClick={() => handleImgClick("Anulingus")} />        
        <img src={sixNine_Icon} style={{cursor: 'pointer', height: '100px'}} onClick={() => handleImgClick("69")}/>

        <img src={missionary_Icon} style={{cursor: 'pointer', height: '100px'}} onClick={() => handleImgClick("Missionaire")} />
        <img src={missionaryDebout_Icon} style={{cursor: 'pointer', height: '100px'}} onClick={() => handleImgClick("Missionaire debout")} />
        <img src={bateauIvre_Icon} style={{cursor: 'pointer', height: '100px'}} onClick={() => handleImgClick("Bateau ivre")} />
        <img src={eagle_Icon} style={{cursor: 'pointer', height: '100px'}} onClick={() => handleImgClick("Aigle")} />
        <img src={slowDance_Icon} style={{cursor: 'pointer', height: '100px'}} onClick={() => handleImgClick("Danse lente")} />

        <img src={petiteCuilleres_Icon} style={{cursor: 'pointer', height: '100px'}} onClick={() => handleImgClick("Petite cuillère")} />
        <img src={grasshopper_Icon} style={{cursor: 'pointer', height: '100px'}} onClick={() => handleImgClick("Sauterelle")} />
        <img src={vis_Icon} style={{cursor: 'pointer', height: '100px'}} onClick={() => handleImgClick("Vis")} />

        <img src={levrette_Icon} style={{cursor: 'pointer', height: '100px'}} onClick={() => handleImgClick("Levrette")} />
        <img src={levretteDebout_Icon} style={{cursor: 'pointer', height: '100px'}} onClick={() => handleImgClick("Levrette debout")} />
        <img src={unionElephant_Icon} style={{cursor: 'pointer', height: '100px'}} onClick={() => handleImgClick("Union de l'éléphant")} />

        <img src={placeConfort_Icon} style={{cursor: 'pointer', height: '100px'}} onClick={() => handleImgClick("Place confortable")} />
        <img src={chaiseBascule_Icon} style={{cursor: 'pointer', height: '100px'}} onClick={() => handleImgClick("Chaise à bascule")} />
        <img src={cowgirl_Icon} style={{cursor: 'pointer', height: '100px'}} onClick={() => handleImgClick("Cowgirl")} />
        <img src={andromaque_Icon} style={{cursor: 'pointer', height: '100px'}} onClick={() => handleImgClick("Andromaque")} />

        <img src={hommeDebout_Icon} style={{cursor: 'pointer', height: '100px'}} onClick={() => handleImgClick("Homme debout")} />
        <img src={HommePorte_Icon} style={{cursor: 'pointer', height: '100px'}} onClick={() => handleImgClick("Homme porte")} />
        <img src={leClou_Icon} style={{cursor: 'pointer', height: '100px'}} onClick={() => handleImgClick("Le clou")} />
        
        <img src={ejaculation_Icon} style={{cursor: 'pointer', height: '100px'}} onClick={() => handleImgClick("Ejaculation")} />
      </ChapterButtonGrid>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <img src={other_Icon} style={{cursor: 'pointer', height: '50px'}} onClick={() => handleButtonClick()}/>
      <input
        type="text"
        placeholder="Position name"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        style={{ marginLeft: '10px', padding: '5px', fontSize: '16px', marginTop: '5px' }}
      />
      </div>

      <h1>Chapters list</h1>
      <Table>
        <thead>
          <tr>
            <Th>Chapters name</Th>
            <Th>Start time (sec)</Th>
            <Th>End time (sec)</Th>
            <Th>Remove line ?</Th>
          </tr>
        </thead>
        <tbody>
          {allChapitre.length > 0 ? (
            allChapitre.map((chap, index) => (
              <Tr key={index}>
                <Td>{chap.chapitre_name}</Td>
                <Td>{chap.start_time}</Td>
                <Td>{chap.end_time}</Td>
                <Td>
                  <RemoveButton onClick={() => handleRemoveChapter(index)}>
                    &#x2715;
                  </RemoveButton>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={4}>0 chapter</Td>
            </Tr>
          )}
        </tbody>
      </Table>

      <button
        onClick={handleDoneButton}
        style={{
          padding: '10px 20px',
          fontSize: '16px',
          borderRadius: '5px',
          backgroundColor: '#007bff',
          color: 'white',
          border: 'none',
          cursor: videosLinks[index] ? 'pointer' : 'not-allowed', // Changer le curseur si désactivé
          opacity: videosLinks[index] ? 1 : 0.6  // Rendre le bouton plus transparent s'il est désactivé
        }}
        disabled={!videosLinks[index]}
      >
        Done
      </button>
    </div>
  );
};

export default ScrappingScenario;
