import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import axios from 'axios';

// Styled-components pour styliser la page
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
`;

const LoginBox = styled.div`
  width: 300px;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  text-align: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
`;

// Composant principal de la page de login
const LoginPage: React.FC<{ onVerify: (verified: boolean) => void }> = ({ onVerify }) => {
  // États pour le nom d'utilisateur et le mot de passe
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');

  const [plageStart, setPlageStart] = useState<string>();
  const [plageEnd, setPlageEnd] = useState<string>();

  const fetchUserPlage = async () => {
    setError('');
    try {
      const response = await axios.get('https://xclusiveserveurintern.online/api/ANTIMUS/user_plage', {
        params: { username, password },
      });
  
      // Vérifier le message renvoyé par le serveur
      if (response.data.message === 'success') {
        setPlageStart(response.data.plage_start);
        setPlageEnd(response.data.plage_end);
      } else if (response.data.message === 'failed') {
        setError('Invalid username or password.');
      }
    } catch (error) {
      setError('Error. Please contact the admin.');
      console.error('Erreur lors de la récupération des plages :', error);
    }
  };

  useEffect(() => {
    // Cette fonction sera appelée chaque fois que `plageStart` change
    if (plageStart && plageEnd) {
      localStorage.setItem('plageStart', plageStart);
      localStorage.setItem('plageEnd', plageEnd);
      localStorage.setItem('username', username);
      onVerify(true);
    }
  }, [plageStart, plageEnd]);

  // Gestion de la soumission du formulaire de login
  const handleLogin = () => {
    if(username && password) {
      fetchUserPlage();
    }
  };

  return (
    <Container>
      <LoginBox>
        <h2>Login</h2>
        <Input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <Button onClick={handleLogin}>Log in</Button>
      </LoginBox>
    </Container>
  );
};

export default LoginPage;
