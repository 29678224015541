import React from 'react';
import styled from '@emotion/styled';

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

type SpinnerProps = {
  width?: string;
  height?: string;
};

const Spinner = styled.div<SpinnerProps>`
  border: 8px solid rgba(0, 0, 0, 0.1); /* Bordure légère */
  border-top: 8px solid #EA6666; /* Bordure supérieure colorée pour l'effet de rotation */
  border-radius: 50%;
  width: ${({ width }) => width || '60px'}; /* Taille dynamique par défaut à 60px */
  height: ${({ height }) => height || '60px'};
  animation: spin 3s linear infinite; /* Animation de rotation */

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const LoadingSpinner = ({ width, height }: SpinnerProps) => {
  return (
    <LoaderContainer>
      <Spinner width={width} height={height} />
      </LoaderContainer>
  );
};

export default LoadingSpinner;